<template>
  <div>
    <nav class="navbar is-fixed-top is-primary" ref="navbar">
      <div class="container is-fluid">
        <div class="navbar-brand">
          <div class="navbar-item">
            <TimberwolfLogo />
            <span><span class="has-text-weight-bold">WolfCenter</span><span class="has-text-weight-light">Studios</span></span>
          </div>
          <a role="button" class="navbar-burger" :class="{ 'is-active': showMenu }" @click="toggleMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div class="navbar-menu" :class="{ 'is-active': showMenu }">
          <div class="navbar-start">
            <router-link :to="{ name: 'episodes' }" class="navbar-item">Episodes</router-link>
          </div>
        </div>
      </div>
    </nav>
    <main class="py-4" ref="main">
      <router-view />
    </main>
    <footer class="py-5 has-text-centered has-text-grey" ref="footer">
      <p>
        WolfCenter Studios is a production of
        <span class="is-hidden-tablet"><br></span>
        <a href="https://www.leonschools.net/chiles" target="_blank" rel="noopener">Lawton Chiles High School</a>.
      </p>
      <p>
        These shows provide school news and
        <span class="is-hidden-tablet"><br></span>
        are meant for educational purposes.
      </p>
      <p class="has-text-grey-light">
        <small>Sponsored by Bradley B. Smith.</small>
      </p>
    </footer>
  </div>
</template>

<script>
  import TimberwolfLogo from '@/assets/timberwolf.svg?inline'

  export default {
    name: 'app',

    components: {
      TimberwolfLogo,
    },

    data: () => ({
      showMenu: false,
    }),

    created() {
      document.body.classList.add('has-navbar-fixed-top')
    },

    mounted() {
      this.resizeMain()
    },

    methods: {

      toggleMenu() {
        this.showMenu = !this.showMenu
      },

      resizeMain() {
        const height = this.$refs['navbar'].clientHeight + this.$refs['footer'].clientHeight
        this.$refs['main'].style.minHeight = `calc(-${height}px + 100vh)`
      },

    },
  }
</script>

<style lang="scss">
  @charset "utf-8";

  @import "~bulma/sass/base/_all";
  @import "~bulma/sass/elements/container";
  @import "~bulma/sass/elements/image";
  @import "~bulma/sass/form/shared";
  @import "~bulma/sass/form/select";
  @import "~bulma/sass/form/tools";
  @import "~bulma/sass/components/level";
  @import "~bulma/sass/components/menu";
  @import "~bulma/sass/components/modal";
  @import "~bulma/sass/components/navbar";
  @import "~bulma/sass/grid/_all";
  @import "~bulma/sass/helpers/_all";

  // Our Bulma customizations.
  @import "@/sass/elements/container";
  @import "@/sass/components/menu";
  @import "@/sass/components/navbar";
</style>
