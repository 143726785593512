import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  linkActiveClass: 'is-active',

  routes: [
    {
      path: '/',
      redirect: '/episodes',
    },
    {
      name: 'episodes',
      path: '/episodes/:season?',
      component: () => import('@/views/Episodes.vue'),
      props: (route) => ({ season: Number(route.params.season) }),
    },
    //{
    //  name: 'staff',
    //  path: '/staff',
    //  component: () => import('@/views/Staff.vue'),
    //},
  ],

  scrollBehavior: (to, from, saved) => saved || { x: 0, y: 0 },
})

export default router
