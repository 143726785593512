const utils = {
    cdn: (path = '') => `${process.env.VUE_APP_CDN}/${path}`,

    episode_path: (season, date, format = 'mp4') => {
        switch (format) {
            case 'mp4': return utils.cdn(`videos/episodes/S${season}/${date}.mp4`)
            //case 'hls': return utils.cdn(`videos/${season}/${date.replace(/-/g, '')}/episode.m3u8`)
        }

        throw new Error(`Invalid episode format: ${format}`)
    },
}

export default utils
