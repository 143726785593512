export default {
    install(Vue) {
        Vue.filter('secondsFormat', (val) => {
            return Math.floor(val / 60) + ':' + String(val % 60).padStart(2, '0')
        })

        Vue.filter('numberFormat', (val) => {
            return Number(val).toLocaleString()
        })
    },
}
