import Vue from 'vue'
Vue.config.productionTip = false

import App from "@/App.vue"
import router from "@/router"
import axios from '@/lib/axios'
import filters from '@/lib/filters'

Vue.use(axios)
Vue.use(filters)

// Instantiate our root Vue instance.
new Vue({
    router,
    ...App,
}).$mount("#app")
