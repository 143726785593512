import axios from 'axios'
import util from './utils'

let vm

export default {
  install(Vue) {

    // Get a reference to the Vue root instance.
    Vue.mixin({
      beforeCreate() {
        vm = vm ? vm : this
      },
    })

    // Instantiate a CDN instance of axios.
    Vue.prototype.$cdn = axios.create({
      baseURL: util.cdn()
    })

  },
}
